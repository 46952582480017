.header {
    align-items: center;
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    padding-top: 5px;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: #FCBA12;
}