.books {
    text-align: center;
}

.bookContent {
    padding: 10px;
}

.bookHeader {
    font-size: x-large;
}

.bookSubHeader {
    padding-bottom: 10px;
    font-style: italic;
    font-size: small;
}

.bookImage {
    width: 100%;
    height: auto;
    max-width: 300px;
}

.bookDetail {
    text-align: center;
    padding-left: 15%;
    padding-right: 15%;
    font-size: large;
}

.bookFooter img {
    height: 50px;
}