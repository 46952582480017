.nav {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    color: #342309;
    text-decoration: none;
    padding: 5px;
}

.navButton :hover {
	border: none;
	cursor: pointer;
    color: white;
}

.navButton {
    background: none;
	border: none;
	cursor: pointer;
    color: #342309;
}