.container {
    background-image: url("https://media.tenor.com/FOxYFhcMTXIAAAAC/timeless-clock.gif");
    background-repeat: no-repeat;
    background-position: center;
    height: 100%;
}

.image {
    height: 300px;
    padding: 5px;
}

.images {
    
    text-align: center;
}

.subContainer {
    padding-top: 10px;
    text-align: center;
    font-size: larger;
}