.bio {
    text-align:center;
}

.bioimage {
    border-radius: 50px;
    width: 100%;
    height: auto;
    max-width: 300px;
}

.biocontent {
    text-align: center;
    padding-left: 15%;
    padding-right: 15%;
    font-size: large;
}

.header {
    font-size: x-large;
    padding-bottom: 10px;
}